import {
  deleteCookie,
  getCookieWithDefaultValue,
  setCookie
} from 'lib/utils/cookieUtils';

import { generate } from './anonIdGenerator';
import {
  AnonymousUserIdAdapter,
  anonymousIdCookieName,
  cookieMaxAgeInMilliseconds
} from './api';

class AnonymousUserIdClientAdapter implements AnonymousUserIdAdapter {
  getId(): string | null {
    const userUUID = getCookieWithDefaultValue(anonymousIdCookieName);

    return userUUID || null;
  }

  setId(id: string): void {
    setCookie(
      anonymousIdCookieName,
      id,
      new Date(Date.now() + cookieMaxAgeInMilliseconds)
    );
  }

  generateId(): string {
    return generate();
  }

  clearId(): void {
    deleteCookie(anonymousIdCookieName);
  }

  findOrCreateId({ onNotFound }: { onNotFound: () => void }): string {
    let id = this.getId();

    if (!id || id === '') {
      id = this.generateId();
      this.setId(id);
      onNotFound();
    }

    return id;
  }
}

export { AnonymousUserIdClientAdapter };
