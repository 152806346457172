import Avatar from '@oneflare/flarekit/lib/components/Avatar';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const AuthorHeaderContainer = styled.section`
  display: grid;
  grid-template-areas:
    'avatar full-name'
    'avatar time-and-read'
  ;
  grid-column-gap: ${({ theme }) => theme.spacing.md};
  grid-template-rows: 1fr;
  margin-block-end: ${({ theme }) => theme.spacing.xxl};
  grid-template-columns: auto 1fr;
`;

export const AuthorHeaderAvatar = styled(Avatar)`
  grid-area: avatar;
`;

export const AuthorHeaderNameText = styled(Paragraph)`
  grid-area: full-name;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.charcoal};
`;

export const AuthorHeaderTimeAndReadText = styled(Paragraph)`
  grid-area: time-and-read;
  font-size: 13px;
  color: ${({ theme }) => theme.color.silver};

  .divider {
    display: none;
  }

  ${media.mdUp`
    .break-point {
      display: none
    }
    .divider {
      display: initial;
    }
  `}
`;
