import H2 from '@oneflare/flarekit/lib/components/H2';
import H3 from '@oneflare/flarekit/lib/components/H3';
import DownwardChevron from '@oneflare/flarekit/lib/components/icons/DownwardChevron';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled, css } from 'styled-components';

import { publicRuntimeConfig } from 'lib/utils/Environment';

const TitleStyles = css`
  color: ${({ theme }) => (publicRuntimeConfig.DOMAIN === 'wedding' ? theme.color.secondary : theme.color.charcoal)};
  font-size: 16px;
  margin: 0;
  padding: 0 8px 0 0;
  flex-shrink: 1;

  ${media.mdUp`
    font-size: 18px;
  `}
`;

export const Title = styled(H2)`
  ${TitleStyles}
`;

export const DowngradedTitle = styled(H3)`
  ${TitleStyles}
`;

export const AccordionContainer = styled.section`
  &:not(:last-of-type) {
    &:after {
      display: flex;
      content: '';
      background-color: ${({ theme }) => (publicRuntimeConfig.DOMAIN === 'oneflare' ? theme.color.cloud : theme.color.nickel)};
      height: 1px;
      margin: 32px 0;
    }
  }

  ${media.smUp`
    &:not(:last-of-type) {
      &:after {
        margin: 40px 0;
      }
    }
  `}
`;

export const AccordionHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`;

export const Chevron = styled(DownwardChevron).attrs(({ theme }) => ({
  kind: 'hollow',
  fill: publicRuntimeConfig.DOMAIN === 'wedding' ? theme.color.primary : theme.color.spaceGrey
}))`
  path {
    stroke-width: 2px;
  }

  height: 20px;
  width: 20px;
  flex-shrink: 0;
  transition: transform .4s linear;
  ${({ toggled }) => toggled && 'transform: rotate(180deg);'}

  ${media.smUp`
    height: 24px;
    width: 24px;
    path {
      stroke-width: 0;
    }
  `}
`;

export const AccordionInner = styled.section`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  padding: 16px 0 0;
  ${media.mdUp`
    margin: 0 50px 0 0;
  `}
`;
