import H2 from '@oneflare/flarekit/lib/components/H2';
import H3 from '@oneflare/flarekit/lib/components/H3';
import H4 from '@oneflare/flarekit/lib/components/H4';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const StatsStyled = styled.div`
  padding: 40px 20px;
  text-align: center;
  width: 100%;
  ${media.mdUp`
    padding: 40px;
  `}

  ${media.lgUp`
    padding: 80px;
  `}
`;

export const StatsWrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

export const StatsHeaderStyled = styled(H2)`
  font-family: ${({ theme }) => theme.font.secondaryBold};
  text-align: center;
  margin-bottom: 24px;

  ${media.lgUp`
    margin-bottom: 28px;
  `}
`;

export const H3Styled = styled(H3)`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  margin: 0;
  text-align: center;

  ${media.mdUp`
    margin: 0 0 32px;
  `}

  ${media.lgUp`
    font-size: 18px;
  `}
`;

export const StatsFigureStyled = styled(H2)`
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 40px;
  line-height: 51px;
  margin: 40px 0 8px;

  ${media.smUp`
    margin: 16px;
  `}

  ${media.mdUp`
    margin: 8px 0;
  `}

  ${media.lgUp`
    font-size: 60px;
    margin: 16px 0 8px;
  `}
`;

export const StatsDescriptionStyled = styled(H4)`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  margin-bottom: 0;
`;

export const StatsFactStyled = styled.div`
  text-align: center;

  &:last-child h4 {
    margin-bottom: 0;
  }

  ${media.smUp`
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  `}

  ${media.lgUp`
    margin-bottom: 0;
  `}
`;

export const H2Styled = styled(H2)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  margin-bottom: 16px;
  text-align: center;
  font-size: 22px;

  ${media.lgUp`
    font-size: 24px;
  `}
`;

export const FactsStyled = styled.div`
  ${media.smUp`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  `}
`;
