import type { FunctionComponent } from 'react';

import { useGenerateAvatarBackgroundColor } from 'shared/utils/hooks';

import { generatePrevDate, formattedAuthorNameForArticle } from './helpers/utils';
import * as Styled from './styled/CostGuideAuthorHeaderStyled';

type CostGuideHeaderAuthorProps = {
  authorName: string;
  timeStamp: string;
  timeToRead: number;
}

const CostGuideHeaderAuthor: FunctionComponent<CostGuideHeaderAuthorProps> = ({
  authorName,
  timeStamp,
  timeToRead
} ) => {
  const avatarBgColor = useGenerateAvatarBackgroundColor(authorName);
  const { displayDate } = generatePrevDate({ timeStamp });
  const [firstName] = authorName.split(' ');
  const formattedAuthorName = formattedAuthorNameForArticle(firstName);
  const [firstLetter] = formattedAuthorName;

  return (
    <Styled.AuthorHeaderContainer data-testid="author-avatar-container">
      <Styled.AuthorHeaderAvatar
        data-testid="author-head"
        avatarTextBackground={avatarBgColor}
        avatarText={firstLetter}
        avatarSize={{ default: '48px', mdUp: '48px' }}
      />
      <Styled.AuthorHeaderNameText data-testid="author-name">
        {formattedAuthorName}
      </Styled.AuthorHeaderNameText>
      <Styled.AuthorHeaderTimeAndReadText data-testid="author-timestamp-container">
        <span data-testid="author-timestamp">
          Updated
          {' '}
          {displayDate}
        </span>
        <span className="divider"> | </span>
        <br className="break-point" />
        <span data-testid="author-reading-time">
          {timeToRead}
          {' '}
          minute read
        </span>
      </Styled.AuthorHeaderTimeAndReadText>
    </Styled.AuthorHeaderContainer>
  );
};

export default CostGuideHeaderAuthor;
