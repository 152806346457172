import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import PropTypes from 'prop-types';

import {
  Quote,
  QuoteMainTitle,
  QuoteLocation,
  QuotePrice,
  QuotePriceGst,
  QuoteText,
  QuoteTitle,
  LocationPinStyled,
  SampleQuoteStyled,
  SampleQuoteContainerStyled,
  H2Styled,
  Wrapper
} from './styled/SampleQuotes';

const propTypes = {
  heading: PropTypes.string,
  quotes: PropTypes.arrayOf(PropTypes.shape({
    cost: PropTypes.string.isRequired,
    description1: PropTypes.string.isRequired,
    description2: PropTypes.string.isRequired,
    description3: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    subheading1: PropTypes.string.isRequired,
    subheading2: PropTypes.string.isRequired,
    subheading3: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    businessName: PropTypes.string.isRequired
  }))
};

const defaultProps = {
  heading: '',
  quotes: []
};

const SampleQuotes = (props) => {
  const { heading, quotes } = props;

  if (!quotes && !heading) return null;

  return (
    <SampleQuoteStyled>
      {heading
        && <H2Styled>{heading}</H2Styled>}
      {quotes
        && (
        <SampleQuoteContainerStyled>
          {quotes.map((card) => (
            <Quote key={card.title}>
              <Wrapper>
                <QuoteMainTitle>{card.title}</QuoteMainTitle>
                <QuoteLocation>
                  <LocationPinStyled />
                  {card.location}
                </QuoteLocation>
                <QuoteTitle>{card.subheading1}</QuoteTitle>
                <QuoteText>{card.description1}</QuoteText>
                <QuoteTitle>{card.subheading2}</QuoteTitle>
                <QuoteText>{card.description2}</QuoteText>
                <QuoteTitle>{card.subheading3}</QuoteTitle>
                <QuoteText>{card.description3}</QuoteText>
                <QuotePrice>
                  {`$${card.cost}`}
                  <QuotePriceGst>inc. gst</QuotePriceGst>
                </QuotePrice>
                <QuoteText>
                  {'Quoted by '}
                  <Anchor href={card.link}>{card.businessName}</Anchor>
                </QuoteText>
              </Wrapper>
            </Quote>
          ))}
        </SampleQuoteContainerStyled>
        )}
    </SampleQuoteStyled>
  );
};

SampleQuotes.propTypes = propTypes;
SampleQuotes.defaultProps = defaultProps;

export default SampleQuotes;
