import H2 from '@oneflare/flarekit/lib/components/H2';
import H3 from '@oneflare/flarekit/lib/components/H3';
import H5 from '@oneflare/flarekit/lib/components/H5';
import LocationPin from '@oneflare/flarekit/lib/components/icons/LocationPin';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const Quote = styled.div`
  color: ${({ theme }) => theme.color.gunMetal};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  line-height: 1.5;

  &:not(:first-child) {
    display: none;
  }

  ${media.mdUp`
    &:nth-of-type(2) {
      display: block;
    }
  `}

  ${media.lgUp`
    &:not(:first-child) {
      display: block;
    }
  `}
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.nickel};
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0 rgba(88, 89, 91, .05);
  height: 100%;
  padding: 24px;
  text-align: left;

  ${media.mdUp`
    padding: 32px;
  `}

  &:not(:last-child){
    margin-right: 32px;
  }
`;

export const QuoteMainTitle = styled(H2)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 18px;
  margin-bottom: 8px;
`;

export const QuoteLocation = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

export const LocationPinStyled = styled(LocationPin).attrs(({ theme }) => ({
  fill: theme.color.gunMetal,
  kind: 'solid'
}))`
  line-height: 0;
  margin-right: 8px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const QuoteTitle = styled(H5)`
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
`;

export const QuoteText = styled(Paragraph)`
  font-size: 16px;
  margin: 0 0 16px;

  &:last-child {
    margin: 0;
  }
`;

export const QuotePrice = styled(H3)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 28px;
  margin: 0;
`;

export const QuotePriceGst = styled.span`
  color: ${({ theme }) => theme.color.gunMetal};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  margin-left: 4px;
`;

export const SampleQuoteStyled = styled.div`
  background-color: ${({ theme }) => theme.color.cloud};
  border-bottom: 1px solid ${({ theme }) => theme.color.cloud};
  padding: 40px 0;
  text-align: center;

  ${media.mdUp`
    padding: 40px;
  `}

  ${media.lgUp`
    padding: 80px;
  `}
`;

export const SampleQuoteContainerStyled = styled.section`
  padding: 24px 20px 0;

  ${media.mdUp`
    padding: 0;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.lgUp`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const H2Styled = styled(H2)`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 22px;
  margin: 0;
  padding: 0 20px;
  text-align: center;

  ${media.smUp`
    font-size: 28px;
    margin-bottom: 32px;
    padding: 0;
  `}
`;
