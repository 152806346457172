import H2 from '@oneflare/flarekit/lib/components/H2';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled, css } from 'styled-components';

import { publicRuntimeConfig } from 'lib/utils/Environment';

export const FAQWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FAQHeaderStyles = css`
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 22px;
  color: ${({ theme }) => (publicRuntimeConfig.DOMAIN === 'wedding' ? theme.color.secondary : theme.color.charcoal)};
  margin: 40px 0 32px;
  max-width: 280px;
  text-align: center;

  ${media.smUp`
    margin-top: 64px;
  `}

  ${media.mdUp`
    margin: 80px 0 56px;
    font-size: 28px;
    max-width: none;
  `}
`;

export const FAQTitle = styled(H2)`
  ${FAQHeaderStyles};
`;

export const FAQDowngradedTitle = styled(H2)`
  ${FAQHeaderStyles};
`;

export const FAQAccordionSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 0 32px;
  width: 100%;

  ${media.lgUp`
    margin: 0 150px 32px;
  `}
`;
