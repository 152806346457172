const cookieMaxAgeInMilliseconds = 90 * 24 * 60 * 60 * 1000; // 90 days
const anonymousIdCookieName = 'of_anonymous_id';

interface AnonymousUserIdAdapter {
  /**
   * Returns the id or null if the id does not exist or is empty.
   */
  getId(): string | null;
  setId(id: string): void;
  generateId(): string;
  clearId(): void;
}

export { cookieMaxAgeInMilliseconds, anonymousIdCookieName };

export type { AnonymousUserIdAdapter };
