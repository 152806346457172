import PropTypes from 'prop-types';

import {
  LinkStyled,
  ListItemStyled,
  ListStyled,
  H2Styled
} from './styled/RelatedArticles';

const propTypes = {
  heading: PropTypes.string,
  relatedArticles: PropTypes.shape({
    articles: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    }))
  })
};

const defaultProps = {
  heading: '',
  relatedArticles: {}
};

const RelatedArticles = ({ relatedArticles, heading }) => (
  <>
    {heading
      && <H2Styled>{heading}</H2Styled>}
    {relatedArticles?.articles?.map(({ title, url }) => (
      <ListStyled key={title}>
        <ListItemStyled>
          <LinkStyled kind="default" href={url} target="_blank">
            {title}
          </LinkStyled>
        </ListItemStyled>
      </ListStyled>
    ))}
  </>
);

RelatedArticles.propTypes = propTypes;
RelatedArticles.defaultProps = defaultProps;

export default RelatedArticles;
