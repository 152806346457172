import PropTypes from 'prop-types';
import { memo } from 'react';
import { isMobile } from 'react-device-detect';

import { generateRefNameFor } from 'shared/utils/helpers';
import { useBreakpoint } from 'shared/utils/hooks';

import * as Styled from './styled/QuestionsAndAnswers';

const propTypes = {
  activeSection: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleActiveName: PropTypes.func.isRequired,
  handleTitleClick: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

type QuestionsAndAnswersProps = PropTypes.InferProps<typeof propTypes>;

const QuestionsAndAnswers = ({
  title,
  setRef,
  subtitle,
  index,
  description,
  handleTitleClick,
  handleActiveName
}: QuestionsAndAnswersProps) => {

  const refName = generateRefNameFor(subtitle);
  const mdUp = useBreakpoint('md');

  const handleClick = () => {
    if (isMobile || !mdUp) {
      handleActiveName(refName);
      handleTitleClick(refName, true);
    }
  };

  return (
    <Styled.QuestionsAndAnswersStyled ref={(section) => setRef(section, refName)}>
      <Styled.TitleSectionStyled onClick={handleClick}>
        <Styled.H2Styled data-testid={`question-${index}`}>{title}</Styled.H2Styled>
      </Styled.TitleSectionStyled>
      <Styled.AnswerStyled data-testid={`answer-${index}`} dangerouslySetInnerHTML={{ __html: description }} />
    </Styled.QuestionsAndAnswersStyled>
  );
};

export default memo(QuestionsAndAnswers);
