import H2 from '@oneflare/flarekit/lib/components/H2';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

import CostStats from 'shared/components/CostStats';
import FrequentlyAskedQuestions from 'shared/components/FrequentlyAskedQuestions';

import { QuestionsAndAnswersStyled } from '../components/QuestionsAndAnswers/styled/QuestionsAndAnswers';

export const CostGuideColStyled = styled.div`
  padding: 0;

  ${/* sc-selector */QuestionsAndAnswersStyled}:last-of-type {
    margin-bottom: 0;
  }
`;

export const QuestionSummaryStyled = styled.div`
  display: none;

  ${media.mdUp`
    display: block;
    position: sticky;
    top: 40px;
  `}

  ${media.lgUp`
    top: 64px;
  `}
`;

export const QuestionSummaryWrapperStyled = styled.div`
  position: relative;
`;

export const QuestionsAndAnswersSection = styled.section`

  max-width: 990px;
  margin: 0 auto;
  border-bottom: 1px solid ${({ theme }) => theme.color.cloud};
  padding: 40px 20px;

  ${media.mdUp`
    padding: 40px 20px 64px;
    display: grid;
    grid-gap: 0 32px;
    grid-template-columns: 240px 1fr;
  `}

  ${media.lgUp`
    padding: 64px 0 80px;
    grid-template-columns: 330px 1fr;
  `}
`;

export const H2Styled = styled(H2)`
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  margin-bottom: 24px;

  ${media.lgUp`
    margin-bottom: 28px;
  `}
`;

export const CostStatsStyled = styled(CostStats)`
  border-bottom: 1px solid ${({ theme }) => theme.color.cloud};
`;

export const FaqStyled = styled(FrequentlyAskedQuestions)`
  max-width: 990px;
  margin: 0 auto 40px;
  padding: 0 20px;

  ${media.mdUp`
    margin: 0 auto 80px;
  `}

  ${media.lgUp`
    padding: 0;
  `}
`;
